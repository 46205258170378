<template>
  <div>
    <DataTableComponent :table="table"></DataTableComponent>
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";

export default {
  name: "AllTransactions",
  components: { DataTableComponent },
  data() {
    return {
      table: {
        modal: "Warehouse Transactin",
        path: "WarehouseTransaction",
        headers: [
          {
            text: this.$i18n.t("Warehouse Transaction ID"),
            value: "transaction_id",
          },
          {
            text: this.$i18n.t("Transaction Date"),
            value: "transaction_date",
          },
          // {
          //   text: "Notes",
          //   value: "notes"
          // },
          {
            text: this.$i18n.t("Type in/out"),
            value: "type",
          },
          {
            text: this.$i18n.t("Partner"),
            value: "partner",
          },
          {
            text: this.$i18n.t("Total Price"),
            value: "total_price",
          },
          {
            text: this.$i18n.t("Tax"),
            value: "tax",
          },
          {
            text: this.$i18n.t("Total after tax"),
            value: "total_after_tax",
          },
          {
            text: this.$i18n.t("created By"),
            value: "created_by",
          },
          {
            text: this.$i18n.t("Status"),
            value: "confirmed",
          },

          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            class: "moreWidth",
          },
        ],
        title: this.$i18n.t("All Transactions"),
        apiEndPoints: {
          list: "warehouses_transactions",
          create: "warehouses_transactions/show/:id",
          edit: "taxes/update",
          delete: "taxes/destroy",
        },
        editedItem: {
          id: "",
          name: "",
          percentage: "",
          notes: "",
          active: "",
          countries: [],
        },
        permissions: {
          list: "list-transactions",
          add: "add-transactions",
          edit: "edit-transactions",
          delete: "delete-transactions",
          print: "print-transactions",
          view: "view-transactions",
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped></style>
